import {describeSeatingPlan} from '@wix/ambassador-seating-v1-seating-plan/http'
import {DescribeSeatingPlanResponse} from '@wix/ambassador-seating-v1-seating-plan/types'
import {GetActivityStatsResponse, SocialGroupsWeb, V2GetGroupResponse} from '@wix/ambassador-social-groups-web/http'
import {
  addQueryParams,
  defaultLocale,
  draftEventPreviewUrlQueryParamName,
  getAmbassadorHeaders,
  GROUPS_API_URL,
  WIX_EVENTS_FQDN,
} from '@wix/wix-events-commons-statics'
import {PlanList, PageData, TrimmedMember} from '@wix/events-types'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import _ from 'lodash'
import {UPDATE_SITE_SETTINGS} from '../../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../../commons/types/state'
import {Api as BaseApi} from '../../../commons/utils/api'
import {GET_DISCOUNT} from '../actions/checkout'
import {GET_CHECKOUT_OPTIONS} from '../actions/checkout-options'
import {PUBLISH_COMPONENT_SETTINGS} from '../actions/component'
import {GET_EVENT, GET_MEMBERS} from '../actions/event'
import {GET_CONNECTED_GROUP, GET_GROUP_ACTIVITY} from '../actions/groups'
import {FETCH_CURRENT_MEMBER, PROMPT_LOGIN} from '../actions/members'
import {GET_PLAN_LIST} from '../actions/paid-plans'
import {GET_ORDER, PLACE_ORDER, UPDATE_ORDER} from '../actions/placed-order'
import {GET_POLICIES} from '../actions/policies'
import {CANCEL_RESERVATION, RESERVE_TICKETS} from '../actions/reservation'
import {DELETE_RSVP, GET_MEMBER_RSVP, SEND_RSVP, UPDATE_RSVP, UPDATE_RSVP_STATUS} from '../actions/rsvp'
import {RegFormData, SelectedTickets, TicketsToPlaces} from '../types'
import {extractFormData} from './api-data-mapper'

export class Api extends BaseApi {
  constructor(controllerParams: ControllerParams) {
    super(controllerParams)

    this.registrar = {
      [GET_EVENT.NAME]: this.getEvent,
      [GET_MEMBERS.NAME]: this.getMembers,
      [GET_CHECKOUT_OPTIONS.NAME]: this.getCheckoutOptions,
      [RESERVE_TICKETS.NAME]: this.makeReservation,
      [CANCEL_RESERVATION.NAME]: this.cancelReservation,
      [GET_MEMBER_RSVP.NAME]: this.getMemberRsvp,
      [SEND_RSVP.NAME]: this.createRsvp,
      [DELETE_RSVP.NAME]: this.deleteRsvp,
      [UPDATE_RSVP.NAME]: this.updateRsvp,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [GET_PLAN_LIST.NAME]: this.getPlanList,
      [UPDATE_ORDER.NAME]: this.updateOrder,
      [PLACE_ORDER.NAME]: this.placeOrder,
      [GET_ORDER.NAME]: this.getOrder,
      [FETCH_CURRENT_MEMBER.NAME]: this.getCurrentMember,
      [UPDATE_RSVP_STATUS.NAME]: this.updateRsvpStatus,
      [GET_DISCOUNT.NAME]: this.getInvoice,
      [PUBLISH_COMPONENT_SETTINGS.NAME]: this.publishComponentSettings,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [GET_POLICIES.NAME]: this.getPolicies,
      [GET_CONNECTED_GROUP.NAME]: this.getGroup,
      [GET_GROUP_ACTIVITY.NAME]: this.getGroupActivity,
    }
  }

  getData = ({slug, responsive = false, schedulePageInstalled, draftPreviewToken}: GetDataArgs) => {
    const encodedSlug = encodeURIComponent(slug)
    const url = addQueryParams(`/html/page-data/${encodedSlug}`, {
      compId: this.compId,
      locale: this.language,
      viewMode: this.viewMode,
      responsive: String(responsive),
      schedule: String(schedulePageInstalled),
      regional: this.locale,
      [draftEventPreviewUrlQueryParamName]: draftPreviewToken,
    })

    return this.api.get<PageData>(url)
  }

  getOrder = async (
    eventId: string,
    orderNumber: string,
    token: string = '',
    locale: string = defaultLocale,
  ): Promise<wix.events.ticketing.Order> => {
    if (token) {
      return this.api.get(`/web/orders/by-token?token=${token}&locale=${locale}`)
    } else {
      return this.api.get(`/web/events/${eventId}/orders/${orderNumber}?locale=${locale}`)
    }
  }

  placeOrder = ({
    eventId,
    buyer,
    guests,
    couponCode,
    memberId,
    planOrderId,
    benefitId,
    policyAgreementToken,
    locale,
  }: PlaceOrderArgs): Promise<{order: wix.events.ticketing.Order}> =>
    this.writeApi.post(`/web/events/${eventId}/checkout`, {
      [guests ? 'buyer' : 'data']: extractFormData(buyer),
      guests,
      reservationId: buyer.reservation,
      memberId,
      couponCode,
      planOrderId,
      benefitId,
      policyAgreementToken,
      locale,
    })

  updateOrder = (
    eventId: string,
    orderNumber: string,
    buyer: RegFormData,
    guests: RegFormData[],
    locale: string,
  ): Promise<wix.events.ticketing.UpdateOrderResponse> =>
    this.writeApi.put(`/web/events/${eventId}/checkout/${orderNumber}`, {buyer, guests, locale})

  getEvent = (eventId: string) => this.api.get(`/web/events/${eventId}/viewer`)

  getMembers = (eventId: string): Promise<{members: Member[]; totalGuests: TotalEventGuests}> =>
    this.writeApi.get(`/web/events/${eventId}/members`)

  getInvoice = (
    eventId: string,
    reservationId: string,
    couponCode?: string,
    benefitId?: string,
    planOrderId?: string,
  ): Promise<wix.events.ticketing.GetInvoiceResponse> => {
    return this.api.put(`/web/events/${eventId}/invoice`, {reservationId, couponCode, benefitId, planOrderId})
  }

  getCheckoutOptions = (): Promise<wix.events.ticketing.GetCheckoutOptionsResponse> =>
    this.writeApi.get('/web/checkout-options')

  makeReservation = (
    eventId: string,
    tickets: SelectedTickets,
    ticketsToPlaces: TicketsToPlaces,
  ): Promise<{id: string; expires: string}> => {
    const ticketQuantities: wix.events.ticketing.TicketReservationQuantity[] = Object.entries(tickets).map(
      ([ticketDefinitionId, {quantity}]) => {
        const ticketDetails: wix.events.ticketing.TicketDetails[] =
          ticketsToPlaces[ticketDefinitionId].reduce((acc, seatId) => {
            const index = acc.findIndex(seat => seat.seatId === seatId)

            if (index !== -1) {
              const currentCapacity = acc[index]?.capacity ?? 1
              acc[index].capacity = currentCapacity + 1
            } else {
              acc.push({seatId})
            }

            return acc
          }, [] as wix.events.ticketing.TicketDetails[]) ?? undefined

        return {
          ticketDefinitionId,
          quantity,
          ticketDetails,
        }
      },
    )
    return this.writeApi.post(`/web/events/${eventId}/reservations`, {
      ticketQuantities,
      userDateTime: new Date().toISOString(),
    })
  }

  cancelReservation = (eventId: string, reservationId: string) =>
    this.writeApi.delete(`/web/events/${eventId}/reservations/${reservationId}`)

  getMemberRsvp = async (eventId: string, memberId: string): Promise<wix.events.rsvp.Rsvp> => {
    return this.api
      .get<{rsvp: wix.events.rsvp.Rsvp}>(`/web/events/${eventId}/member-rsvp?memberId=${memberId}`)
      .then(response => response.rsvp)
  }

  getCurrentMember = (): Promise<{
    lastName: string
    firstName: string
    loginEmail: string
    id: string
  }> => this.api.get<{member: TrimmedMember}>(`/web/members/current`).then(response => response.member)

  getEventsMembersPage = () =>
    this.api.get<{page: {pageId: string}}>('/web/events-members-page').then(response => response.page)

  createRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    memberId?: string,
    policyAgreementToken?: string,
  ): Promise<wix.events.rsvp.CreateRsvpResponse> => {
    return this.writeApi.post(`/web/events/${eventId}/v2/rsvp`, {memberId, status, data, policyAgreementToken})
  }

  deleteRsvp = (eventId: string, rsvpId: string) =>
    this.writeApi.delete(`/web/events/${eventId}/rsvp/${rsvpId}`).then(() => ({eventId}))

  updateRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    rsvpId: string,
  ): Promise<wix.events.rsvp.UpdateRsvpResponse> => {
    return this.writeApi.put(`/web/events/${eventId}/rsvp/${rsvpId}/form`, {data, status})
  }

  updateRsvpStatus = (
    eventId: string,
    rsvpId: string,
    status: wix.events.rsvp.RsvpStatus,
  ): Promise<{eventId: string}> =>
    this.writeApi.put(`/web/events/${eventId}/rsvp/${rsvpId}/status`, {status}).then(() => ({eventId}))

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  getPlanList = (eventId: string): Promise<{plans: PlanList}> => this.api.get(`/web/plans/v2?eventId=${eventId}`)

  updateComponentDraftSettings = _.debounce(
    (settings: any) => this.writeApi.put(`/web/component/${this.controller.compId}/draft`, {component: {settings}}),
    500,
    {trailing: true},
  )

  publishComponentSettings = (settings: any) =>
    this.writeApi.put(`/web/component/${this.controller.compId}`, {component: {settings}})

  updateSiteSettings = (settings: SiteSettingsSettings) => this.writeApi.put(`/web/site-settings`, {settings})

  getPolicies = (eventId: string) => this.api.get(`/web/events/${eventId}/policies?withAgreementToken=true`)

  getGroup = async (eventId: string): Promise<V2GetGroupResponse> => {
    const headers = getAmbassadorHeaders(this.getInstance())

    const {groupIds} = await SocialGroupsWeb(GROUPS_API_URL)
      .SocialGroupsEventsMappingService()(headers)
      .listGroupsByEventId({eventId})

    if (groupIds?.length) {
      return SocialGroupsWeb(GROUPS_API_URL).GroupsService()(headers).getGroup({groupId: groupIds[0]})
    }
  }

  getGroupActivity = async (groupId: string): Promise<GetActivityStatsResponse> =>
    SocialGroupsWeb(GROUPS_API_URL)
      .SocialGroupsActivityService()(getAmbassadorHeaders(this.getInstance()))
      .getActivityStats({groupId})

  describeSeatingPlan = async (eventId: string): Promise<DescribeSeatingPlanResponse> => {
    return this.httpClient
      .request(
        describeSeatingPlan({
          filter: {
            externalId: {
              $eq: `${WIX_EVENTS_FQDN}${eventId}`,
            },
          },
        }),
      )
      .then(r => r.data)
  }
}

interface PlaceOrderArgs {
  eventId: string
  buyer: RegFormData
  guests: RegFormData[]
  couponCode: string
  memberId: string
  planOrderId: string
  benefitId: string
  policyAgreementToken: string
  locale: string
}

interface GetDataArgs {
  slug: string
  responsive: boolean
  schedulePageInstalled: boolean
  draftPreviewToken: string
}
